import React from 'react'
import ListItem from '../components/ListItem'
import { titleIfy, slugify } from '../../utils/helpers'
import CartLink from '../components/CartLink'
import LogLink from '../components/LogLink'
import { setPageProperties,isBrowser } from '../../utils/zeotap'

const CategoryView = (props) => {
  const { pageContext: { title, content: { items = [] }}} = props
  setPageProperties({page_category:title,page_category_level:'1',page_name:title,page_domain:isBrowser() && window.location.hostname})
  return (
    <>
      <CartLink />
      <LogLink />
      <div className="flex flex-col items-center">
        <div className="max-w-fw flex flex-col">
          <div className="pt-10 pb-8">
            <h1 className="text-5xl font-light">{titleIfy(title)}</h1>
          </div>

          <div>
            <div className="flex flex-1 flex-wrap flex-row">
              {
                items.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      link={slugify(item.name)}
                      id={item.id}
                      title={item.name}
                      price={item.price}
                      imageSrc={item.image}
                    />
                  )
                })
              }
            </div>
          </div>
          </div>
      </div>
    </>
  )
}

export default CategoryView