import React from 'react'
import { DENOMINATION } from '../../providers/inventoryProvider'
import Image from './Image'
import { setEventProperties } from '../../utils/zeotap'
import { getSiteConfigField } from '../../utils/helpers'
import ConditionalLink from './ConditionalLink'

const ListItem = ({ link, title, imageSrc, price, id }) =>  (
  <div className="
    w-100
    md:w-1/2
    lg:w-1/4
    p1 sm:p-2
  ">

  {getSiteConfigField('multiPageSite') === 'true' ? (
              <div>
                <a href={`/${link}`} onClick={()=>setEventProperties('viewProduct',{productID: id})}>
                  <div className="h-72 flex justify-center items-center bg-light hover:bg-light-200">
                    <div className="flex flex-column justify-center items-center h-full">
                      <Image alt={title} src={imageSrc} className="w-3/5 showcase max-h-full" />
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div>
                <ConditionalLink redirectUrl={`/${link}`} onClick={()=>setEventProperties('viewProduct',{productID: id})} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
                  <div className="h-72 flex justify-center items-center bg-light hover:bg-light-200">
                    <div className="flex flex-column justify-center items-center h-full">
                      <Image alt={title} src={imageSrc} className="w-3/5 showcase max-h-full" />
                    </div>
                  </div>
                </ConditionalLink>
              </div>
            )}

    <div>
      <p className="m-4 text-center text-l font-semibold mb-1">{title}</p>
      <p className="text-center text-xs text-gray-700 mb-4">{`${DENOMINATION}${price}`}</p>
    </div>
  </div>
)

export default ListItem